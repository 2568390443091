import { useSelector } from "react-redux"
import { globalLoadSelector } from "../../store/reducers/loader"
import styles from "./preloader.module.sass"
import classNames from "classnames"
import { PreloaderImage } from "./PreloaderImage"

export default function Preloader() {
	const loader = useSelector(globalLoadSelector)

	const { location } = window

	if (!loader.loading) {
		return null
	}

	if (!loader.showLoader) {
		return null
	}

	return (
		<>
			<PreloaderImage className={styles.load} />
			<div
				className={classNames(styles.overlay, {
					[styles.login]: location.pathname === "/login",
				})}
			/>
		</>
	)
}
