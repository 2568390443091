import { useTranslation } from "react-i18next";
import { NoItemsImage } from "./NoItemsImage";
import style from "./index.module.sass"; 
import Typography from "../UIKit/base/Typography";

const NoItems = () => {
	const { t } = useTranslation();

	return (
		<Typography component="div" className={style.noItems}>
			<NoItemsImage />
			<h3>{t("no_knowledge_base")}</h3>
			<p>{t("no_knowledge_base_desc")}</p>
		</Typography>
	);
};

export default NoItems;
