import { useEffect } from "react"
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import {
	errorSelector,
	sendFinishManual,
	sendFinishPerformance,
	sendManualAnswer,
	sendPerformanceAnswer,
	setQuestion,
	surveySelector,
	surveysSelector,
} from "../../store/reducers/surveys"
import Typography from "../UIKit/base/Typography"
import InputControl from "../forms/input-control"
import styles from "./ManualSurvey.module.sass"
import Button from "../forms/button/Button"
import { Field, Form, Formik, useField } from "formik"
import Radio from "../forms/radio/Radio"
import { useTranslation } from "react-i18next"
import Checkbox from "../forms/checkbox/Checkbox"
import { useMemo } from "react"
import {
	TimerIcon,
	AnswerCorrectIcon,
	AnswerWrongIcon,
	LampIcon,
	AnonymouseIcon,
	PerformanceIcon,
	QuestionIcon,
} from "../icons"
import { localeFormat } from "../localeFormat"
import { types } from "./utils"
import MobileHeader from "../Mobile/MobileHeader"
import {
	resetBreadcrumbs,
	setBreadcrumbs,
} from "../../store/reducers/breadcrumbs"
import RangeInput from "../forms/range-input"
import EditorView from "../UIKit/editor/view/EditorView"
import ConfirmModal from "../UIKit/modal/confirm-modal/ConfirmModal"
import { isPast } from "date-fns"
import { globalLoadSelector } from "../../store/reducers/loader"
import Page404 from "../404"

function otherIdsValidate(ids) {
	return !ids?.length || (ids?.length === 1 && ids[0] === "other")
}

export default function ManualSurvey() {
	const { t } = useTranslation()
	const { surveyId, questionId, surveyType, personId } = useParams()
	const dispatch = useDispatch()
	const loading = useSelector(globalLoadSelector).loading
	const {
		question,
		questions,
		previous_question_id,
		next_question_id,
		questionnaire,
		target_person,
	} = useSelector(surveySelector)
	const surveys = useSelector(surveysSelector)
	const navigate = useNavigate()
	const initialValues = useMemo(() => {
		if (!question?.answers?.length) {
			return { answer: "", ids: [], scale: 5 }
		}
		const { answers } = question
		const ids = answers
			.filter((e) => e.is_option)
			.map((e) => e?.option_id.toString())
		const answer = answers.find((e) => !e.is_option && e?.type !== "scale")
		const scale = answers.find((e) => e?.type === "scale")
		const other = answers.find((e) => e?.type === "other")
		if (other) {
			ids.push("other")
		}
		return {
			ids,
			answer: answer?.text_answer || "",
			scale: scale?.text_answer || 5,
		}
	}, [question])

	const error = useSelector(errorSelector)

	const isAnswered = useMemo(() => question?.is_answered, [question])

	const surveyInfo = useMemo(() => {
		// eslint-disable-next-line eqeqeq
		return surveys?.find((s) => s?.id == surveyId) || null
	}, [surveys, surveyId])

	const issetSkipped = useMemo(() => {
		// eslint-disable-next-line eqeqeq
		const filtered = questions.filter((q) => q?.id != questionId)
		return filtered.some((f) => !f?.is_answered)
	}, [questions, questionId])

	const crumbs = useMemo(() => {
		const arr = [
			// { path: `/`, title: t("dashboard") },
			{
				path: `/surveys`,
				title: t("scheduled_surveys"),
			},
			{
				path: ``,
				title: surveyInfo?.title,
			},
		]

		return arr
	}, [t, surveyInfo?.title])

	useEffect(() => {
		question && !loading && dispatch(setBreadcrumbs(crumbs))
		return () => {
			dispatch(resetBreadcrumbs())
		}
	}, [crumbs, dispatch, loading])

	const lastRequiredQuestion = useMemo(() => {
		if (!questions?.length) {
			return null
		}
		const filtered = questions.filter((q) => q?.required && !q?.is_answered)
		if (
			filtered?.length === 1 &&
			filtered[0]?.id === question?.id &&
			isAnswered
		) {
			return null
		}
		return filtered?.length ? filtered[0]?.id : null
	}, [questions, question, isAnswered])

	const isLastQuestion = useMemo(() => {
		if (!questions?.length) {
			return null
		}
		return question?.id === questions[questions?.length - 1]?.id
	}, [questions, question])

	function handleFinish({ close, values }) {
		const action =
			surveyType === "performance" ? sendFinishPerformance : sendFinishManual
		if (questionnaire?.status === "completed") {
			return navigate("/surveys", { replase: true })
		}
		if (!isAnswered && validate(values)) {
			return handleNext(values)
		}

		dispatch(
			action({
				surveyId,
				personId,
				cb: () => {
					if (typeof close === "function") {
						close()
					}
					navigate(`/surveys`)
				},
			})
		)
	}
	function validate(values) {
		const { ids, answer, scale, ...other } = values
		const validateOther = Object.keys(other).some((o) => other[o]?.length)
		const validateIds = !otherIdsValidate(ids)
		if (question?.type === "scale") {
			return !!scale
		}
		return validateIds || validateOther || !!answer.trim()?.length
	}

	function handleSkip() {
		if (surveyType === "performance") {
			return navigate(
				`/surveys/${surveyId}/questions/${next_question_id}/${surveyType}/people/${personId}`
			)
		}
		return navigate(
			`/surveys/${surveyId}/questions/${next_question_id}/${surveyType}`
		)
	}

	function handleNext(values) {
		const action =
			surveyType === "performance" ? sendPerformanceAnswer : sendManualAnswer
		const finishAction =
			surveyType === "performance" ? sendFinishPerformance : sendFinishManual
		const notValid = !validate(values)
		if (notValid) {
			return
		}
		if (isAnswered) {
			if (surveyType === "performance") {
				return navigate(
					`/surveys/${surveyId}/questions/${next_question_id}/${surveyType}/people/${personId}`
				)
			}
			return navigate(
				`/surveys/${surveyId}/questions/${next_question_id}/${surveyType}`
			)
		}
		let cb
		if (
			(values?.answer !== initialValues?.answer &&
				(question?.type === "text" || question?.type === "scale")) ||
			question?.type === "text_performance" ||
			question?.type === "radio_performance" ||
			question?.type === "scale" ||
			!questionnaire?.has_correct_and_hints
		) {
			let to = `/surveys/${surveyId}/questions/${next_question_id}/${surveyType}`
			if (surveyType === "performance") {
				to = `/surveys/${surveyId}/questions/${next_question_id}/${surveyType}/people/${personId}`
			}
			cb = () => {
				dispatch(setQuestion(null))
				navigate(to)
			}
		}
		if (!next_question_id) {
			cb = null
		}
		if (
			(!next_question_id &&
				(question.type === "scale" || question.type === "text")) ||
			(!next_question_id && !questionnaire?.has_correct_and_hints)
		) {
			cb = () => {
				dispatch(
					finishAction({
						surveyId,
						personId,
						cb: () => {
							navigate(`/surveys`)
						},
					})
				)
			}
		}
		const { ids, ...other } = values
		const data = ids.filter((i) => i !== "other")
		return dispatch(
			action({
				surveyId,
				questionId,
				personId,
				values: { ...other, ids: data },
				cb,
			})
		)
	}

	function handlePrev() {
		if (surveyType === "performance") {
			return navigate(
				`/surveys/${surveyId}/questions/${previous_question_id}/${surveyType}/people/${personId}`
			)
		}
		return navigate(
			`/surveys/${surveyId}/questions/${previous_question_id}/${surveyType}`
		)
	}

	console.log(error)
	if (error === 404) {
		return <Page404 />
	}

	if (!question || !surveys?.length) {
		return null
	}

	return (
		<>
			<MobileHeader
				title={surveyInfo?.name}
				breadcrumbsTitle={t("scheduled_surveys")}
			/>
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<Typography fontVariant="bold" className={styles.title}>
						{surveyInfo?.title || ""}
					</Typography>
					<div className={styles.header__sub}>
						<Typography>
							{surveyType === "performance"
								? t("performance_review")
								: t("survey")}
						</Typography>
						<Typography
							className={classNames(styles.date, {
								[styles.date_less]:
									surveyInfo?.finish_date_at &&
									isPast(new Date(surveyInfo?.finish_date_at)),
							})}
						>
							<TimerIcon />
							{(!!surveyInfo?.finish_date_at &&
								localeFormat(surveyInfo?.finish_date_at)) ||
								""}
						</Typography>
						{questionnaire?.anonymous && (
							<Typography className={styles.header__anonymouse}>
								<AnonymouseIcon />
								{t("anonymouse_survey")}
							</Typography>
						)}
						{target_person?.name ? (
							<Typography>{target_person?.name}</Typography>
						) : null}
					</div>
				</div>
				<div className={styles.container}>
					<div className={styles.card}>
						<Progress questions={questions} />
						<div className={styles.question__wrapper}>
							<div className={styles.question__types}>
								<QuestionType type={question?.type} />
								{question?.required && (
									<Typography>{t("required_question")}</Typography>
								)}
							</div>

							<Typography fontVariant="semiBold" className={styles.question}>
								{question?.title}
							</Typography>
							{question?.is_description ? (
								<Typography
									component="p"
									className={styles.question__description}
								>
									{question?.description}
								</Typography>
							) : null}
						</div>
						<Formik
							initialValues={initialValues}
							onSubmit={handleNext}
							enableReinitialize
							key={questionId}
							validationSchema={yup.object().shape(
								{
									answer: yup
										.string()
										.matches(/^(?!\s+$)/)
										.when(["ids", "scale"], {
											is: (ids, scale) =>
												question?.rules?.min && question?.rules?.max
													? !(
															ids?.length >= question?.rules?.min &&
															ids?.length <= question?.rules?.max
													  )
													: otherIdsValidate(ids) && !scale?.length,
											then: (schema) =>
												question?.required ? schema.required() : schema,
										}),
									ids: yup
										.array()
										.test("", "", (val) => !otherIdsValidate(val))
										.when(["answer", "scale"], {
											is: (answer, scale) => !answer?.length && !scale?.length,
											then: (schema) =>
												question?.required ? schema.required() : schema,
										}),
									scale: yup.string().when(["ids", "answer"], {
										is: (ids, answer) =>
											otherIdsValidate(ids) && !answer?.length,
										then: (schema) =>
											question?.required ? schema.required() : schema,
									}),
								},
								[
									["ids", "scale"],
									["answer", "scale"],
									["ids", "answer"],
								]
							)}
						>
							{({ values }) => {
								const paramsValidate =
									(!next_question_id && !lastRequiredQuestion) ||
									validate(values)
								const disabled =
									question?.rules?.min && question?.rules?.max
										? !(
												values?.ids?.length >= question?.rules?.min &&
												values?.ids?.length <= question?.rules?.max
										  )
										: !paramsValidate
								return (
									<Form>
										<div className={styles.answers__wrapper}>
											<RenderFields
												type={question?.type}
												choices={question?.options}
												values={values}
												isAnswered={isAnswered}
												other={question?.is_text_answer}
											/>
											{!!question?.hint && (
												<div className={styles.hint}>
													<LampIcon />
													<Typography fontVariant="medium" component="p">
														{question?.hint}
													</Typography>
												</div>
											)}
										</div>
										<div className={styles.button__group}>
											{previous_question_id && (
												<Button
													onClick={handlePrev}
													className={styles.button__prev}
													variant="secondary"
												>
													previous
												</Button>
											)}

											{next_question_id || lastRequiredQuestion ? (
												<div>
													{!question?.required && !question?.is_answered && (
														<Button
															onClick={handleSkip}
															className={styles.button__skip}
															variant="secondary"
														>
															skip
														</Button>
													)}
													<Button disabled={disabled} type="submit">
														{isLastQuestion ? "finish_survey" : "next_question"}
													</Button>
												</div>
											) : (
												<div>
													{issetSkipped ? (
														<ConfirmModal
															confirmType="confirm"
															submitLabel={t("finish")}
															label={t("finish_survey")}
															description={t("finish_survey_description")}
															onSubmit={({ close }) => {
																handleFinish({ close, values })
															}}
															disabled={disabled}
														>
															<Button disabled={disabled} type="button">
																finish_survey
															</Button>
														</ConfirmModal>
													) : (
														<Button
															disabled={disabled}
															type="button"
															onClick={() => {
																handleFinish({ values })
															}}
														>
															finish_survey
														</Button>
													)}
												</div>
											)}
										</div>
									</Form>
								)
							}}
						</Formik>
					</div>
					{questionnaire?.description && (
						<div className={`${styles.card} ${styles.desc}`}>
							<div className={styles.card__header}>
								{surveyType === "performance" ? (
									<>
										<PerformanceIcon />
										<Typography component="span">
											{t("about_review")}
										</Typography>
									</>
								) : (
									<>
										<QuestionIcon />
										<Typography component="span">
											{t("about_survey")}
										</Typography>
									</>
								)}
							</div>
							<EditorView
								content={questionnaire?.description}
								className={styles.about}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

function Progress({ questions }) {
	const { surveyId, questionId, surveyType, personId } = useParams()

	const lastAnswered = useMemo(() => {
		if (!questions?.length) {
			return null
		}
		const index = questions.findLastIndex((l) => l?.is_answered)
		const lastIndex = questions.length - 1

		if (typeof index !== "number" && !index) {
			return 0
		}
		if (index >= lastIndex) {
			return lastIndex
		}
		return index + 1
	}, [questions])

	if (!questions?.length) {
		return null
	}
	return (
		<div className={styles.progress__wrapper}>
			{questions?.map((s, i) => {
				let to = `/surveys/${surveyId}/questions/${s?.id}/${surveyType}`
				if (surveyType === "performance") {
					to = `/surveys/${surveyId}/questions/${s?.id}/${surveyType}/people/${personId}`
				}
				return (
					<Typography
						key={`${s?.id}-question-${i}`}
						to={to}
						component={Link}
						className={classNames(styles.progress, {
							[styles.progress_active]: +questionId === s?.id || s?.is_answered,
							[styles.progress_selected]: +questionId === s?.id,
							[styles.progress_incorrect]:
								typeof s?.is_correct === "boolean" &&
								s?.is_answered &&
								s?.type !== "text" &&
								!s?.is_correct,
							[styles.progress_correct]:
								typeof s?.is_correct === "boolean" &&
								s?.is_answered &&
								s?.type !== "text" &&
								s?.is_correct,
						})}
						onClick={(e) => {
							if (i > lastAnswered) {
								e.preventDefault()
							}
						}}
					/>
				)
			})}
		</div>
	)
}

function Confirmation({ name = "answer" }) {
	const { t } = useTranslation()
	return (
		<div className={styles.choise__wrapper}>
			<Radio name={name} value={1} className={styles.choise}>
				<Typography component="p" className={styles.text}>
					{t("yes")}
				</Typography>
			</Radio>
			<Radio name={name} value={0} className={styles.choise}>
				<Typography className={styles.text}>{t("no")}</Typography>
			</Radio>
		</div>
	)
}

function RadioGroup({ name = "ids", choices, values, isAnswered, other }) {
	const { t } = useTranslation()
	const isOther = !!(values?.ids?.length && values?.ids[0] === "other")

	return (
		<div className={styles.choise__wrapper}>
			{!!choices?.length &&
				choices?.map((c, i) => {
					return (
						<div
							key={`${c?.id}-radio-group-${i}`}
							className={styles.choise__container}
						>
							<Radio
								name={name}
								value={c?.id}
								className={classNames(styles.choise, styles.choise__radio)}
								valueIsArray
								readOnly={isAnswered}
							>
								<Typography component="p" className={styles.text}>
									{c?.title}
								</Typography>
							</Radio>
							{isAnswered && typeof c?.is_correct === "boolean" ? (
								c?.is_correct ? (
									<AnswerCorrectIcon />
								) : (
									<AnswerWrongIcon />
								)
							) : null}
						</div>
					)
				})}
			{other && (
				<div
					className={classNames(
						styles.choise__container,
						styles.choise__container_other
					)}
				>
					<Radio
						name={name}
						className={classNames(styles.choise, styles.choise__radio)}
						value={"other"}
						readOnly={isAnswered}
						valueIsArray
					>
						<Typography component="p" className={styles.text}>
							{t("other")}
						</Typography>
					</Radio>
					{isOther && (
						<Field
							component={InputControl}
							variant="textarea"
							name="answer"
							placeholder={t("enter_you_answer")}
							readOnly={isAnswered}
						/>
					)}
				</div>
			)}
		</div>
	)
}
function CheckboxGroup({ name = "ids", choices, isAnswered, values, other }) {
	const { t } = useTranslation()
	const isOther = values?.ids.includes("other")
	return (
		<div className={styles.choise__wrapper}>
			{!!choices?.length &&
				choices?.map((c, i) => {
					return (
						<div
							key={`${c?.id}-checkbox-group-${i}`}
							className={styles.choise__container}
						>
							<CheckBoxControl
								key={`${c?.id}-checkbox-group-${i}`}
								name={name}
								value={c?.id}
								readOnly={isAnswered}
							>
								<Typography component="p" className={styles.text}>
									{c?.title}
								</Typography>
							</CheckBoxControl>
							{isAnswered && typeof c?.is_correct === "boolean" ? (
								c?.is_correct ? (
									<AnswerCorrectIcon />
								) : (
									<AnswerWrongIcon />
								)
							) : null}
						</div>
					)
				})}
			{other && (
				<div
					className={classNames(
						styles.choise__container,
						styles.choise__container_other
					)}
				>
					<CheckBoxControl
						name={name}
						className={styles.choise}
						value={"other"}
						readOnly={isAnswered}
					>
						<Typography component="p" className={styles.text}>
							{t("other")}
						</Typography>
					</CheckBoxControl>
					{isOther && (
						<Field
							component={InputControl}
							variant="textarea"
							name="answer"
							placeholder={t("enter_you_answer")}
							readOnly={isAnswered}
						/>
					)}
				</div>
			)}
		</div>
	)
}

function CheckBoxControl({ name, value, children, ...props }) {
	const [{ value: formValue }, _, { setValue }] = useField(name)
	function handleChange() {
		if (props?.readOnly) return
		if (formValue.includes(value.toString())) {
			const arr = formValue.filter((e) => e !== value.toString())
			return setValue(arr)
		}
		return setValue([...formValue, value.toString()])
	}
	return (
		<div className={styles.choise}>
			<Checkbox
				{...props}
				name={name}
				value={value}
				className={styles.checkbox}
				onChange={handleChange}
				checked={formValue.includes(value.toString())}
			/>
			{children}
		</div>
	)
}

function RenderFields({ type, choices, values, isAnswered, other }) {
	const { t } = useTranslation()
	if (type === "confirmation") {
		return <Confirmation name="answer" isAnswered={isAnswered} />
	}
	if (type === "radio" || type === "radio_performance") {
		return (
			<RadioGroup
				isAnswered={isAnswered}
				name="ids"
				choices={choices}
				values={values}
				other={other}
			/>
		)
	}
	if (type === "checkbox") {
		return (
			<CheckboxGroup
				isAnswered={isAnswered}
				name="ids"
				choices={choices}
				values={values}
				other={other}
			/>
		)
	}
	if (type === "scale") {
		return (
			<Scale
				isAnswered={isAnswered}
				name="scale"
				values={values}
				other={other}
			/>
		)
	}

	return (
		<Field
			component={InputControl}
			name="answer"
			variant="textarea"
			placeholder={t("enter_your_answer")}
			readOnly={isAnswered}
			className={styles.InputControl}
		/>
	)
}

function QuestionType({ type }) {
	const { t } = useTranslation()
	if (!types[type]) {
		return null
	}
	const Icon = types[type]?.icon
	return (
		<div className={styles.question__type}>
			<Icon />
			<Typography component="span">{t(types[type]?.name)}</Typography>
		</div>
	)
}

function Scale({ isAnswered, name, other, values }) {
	const { t } = useTranslation()
	const [{ value }, _, { setValue }] = useField(name)
	const isOther = other || values?.answer

	function handleChange({ target }) {
		if (isAnswered) return
		const val = target.value
		setValue(val)
	}
	function handleOptionChange({ target }) {
		if (isAnswered) return
		const val = target.dataset.value
		setValue(val)
	}

	return (
		<div className={styles.scale}>
			<div className={styles.range__wrapper}>
				<div className={styles.range}>
					<ul id="values" className={styles.range__value_wrapper}>
						{Array.from(new Array(11), (e, i) => (
							<Typography
								onClick={handleOptionChange}
								component="li"
								fontVariant={window.innerWidth > 576 ? "semiBold" : "regular"}
								className={classNames(styles.range__value, {
									[styles.range__value_active]: i <= +value,
									[styles.range__value_selected]: i === +value,
								})}
								key={i}
								data-value={i}
							>
								{i}
							</Typography>
						))}
					</ul>
					<Typography
						fontVariant={"semiBold"}
						className={styles.range__value_mobile}
					>
						{value}
					</Typography>
					<RangeInput
						value={value || 0}
						min={0}
						max={10}
						step={1}
						name={name}
						onChange={handleChange}
					/>
				</div>
				<div className={styles.range__title_wrapper}>
					<Typography fontVariant="medium" className={styles.range__title}>
						{t("not_at_likely")}
					</Typography>
					<Typography fontVariant="medium" className={styles.range__title}>
						{t("externely_likely")}
					</Typography>
				</div>
			</div>
			{isOther && (
				<Field
					component={InputControl}
					className={styles.range__other}
					variant="textarea"
					name="answer"
					placeholder={t("enter_you_answer")}
					readOnly={isAnswered}
				/>
			)}
		</div>
	)
}
