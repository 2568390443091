import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../../../store/reducers/auth";
import { negateSelector, setNegate } from "../../../store/reducers/domainTheme";
import {
  getUser,
  menuSelector,
  personSelector,
} from "../../../store/reducers/user";
import Logo from "../../Logo/Logo";
import { NotificationsButton } from "../../Notifications/NotificationsButton";
import Avatar from "../../UIKit/avatarView/AvatarView";
import Typography from "../../UIKit/base/Typography";
import useClickOutside from "../../hooks/useClickOutside";
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  BookIcon,
  CloseIcon,
  DashboardIcon,
  DocumentTextIcon,
  KnowbaseIcon,
  TelegramIcon,
  TickIcon,
  ViberIcon,
  WhatsappIcon,
} from "../../icons";
import CustomLink from "../../link/CustomLink";
import JustifyContainer from "../JustifyContainer";
import NegateChangeTheme from "./NegateChangeTheme";
import styles from "./header.module.sass";
import { globalLoadSelector } from "../../../store/reducers/loader";
import PreloaderCircle from "../../Preloader/PreloaderTemplates/PreloaderCircle";
import PreloaderHeader from "../../Preloader/Preloaders/PreloaderHeader";

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector(globalLoadSelector).loading;

  const location = useLocation()
  const path = location.pathname.split("/")[1]

  const learningActive = useMemo(() => {
	return path === "products" || path === "catalog"
  }, [path])

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  if (loading) {
    return <PreloaderHeader />;
  }
  return (
    <>
      <header className={styles.header}>
        <JustifyContainer className={styles.header__container}>
			<div className={styles.header__left}> 
          <Link className={styles.header__logo} to="/">
            <Logo isHeader />
          </Link>
      <HeaderNav baseClass="header" />
		  </div>
          <div className={styles.header__right}>
            <div className={styles.header__right_icon}>
              <NotificationsButton />
              {/* <NegateChangeTheme /> */}
            </div>
            <UserNavigation />
          </div>
        </JustifyContainer>
      </header>
    </>
  );
};

export default Header;

export function HeaderNav({baseClass, type, notificationsCount}) {


  const {t} = useTranslation()
  const location = useLocation()
  const path = location.pathname.split("/")[1]

  const learningActive = useMemo(() => {
	return path === "products" || path === "catalog"
  }, [path])

  const [showSubmenu, setShowSubmenu] = useState(false)

  console.log(type !== "mobile")

  return (
    <div className={styles[`${baseClass}__center`]}>
      {type === "mobile" && 
         <Typography
         component={CustomLink}
         fontVariant="medium"
         to="/"
         className={styles[`${baseClass}__link`]}
         activeClassName={styles[`${baseClass}__link_active`]}
       >
         <span>{t("home")}</span>
       </Typography>
      
      }
    <Typography
            component={CustomLink}
            fontVariant="medium"
            to="/knowledge-base"
            className={styles[`${baseClass}__link`]}
            activeClassName={styles[`${baseClass}__link_active`]}
          >
            {t("knowledge_base")}
          </Typography>
          <div className={styles[`${baseClass}__learning`]}>
            <Typography
              fontVariant="medium"
              className={classNames(styles[`${baseClass}__link`], styles[`${baseClass}__learning_link`], {[styles[`${baseClass}__link_active`]]: learningActive})} 
              onClick={() => setShowSubmenu(p => !p)}
            >
              {t("learning")}
              {type === "mobile" && <>{showSubmenu ? <ArrowUpIcon /> : <ArrowDownIcon />}</>}
            </Typography>
            {((type !== "mobile") || (type === "mobile" && showSubmenu)) && 
            <div className={styles[`${baseClass}__submenu`]}>
              <Typography
                component={CustomLink}
                fontVariant="medium"
                to="/products"
                className={styles[`${baseClass}__link`]}
                activeClassName={styles[`${baseClass}__link_active`]}
              >
                {t("my_courses")}
              </Typography>
              <Typography
                component={CustomLink}
                fontVariant="medium"
                to="/catalog"
                className={styles[`${baseClass}__link`]}
                activeClassName={styles[`${baseClass}__link_active`]}
              >
                {t("catalog")}
              </Typography>
            </div>}
          </div>
    <Typography
            component={CustomLink}
            fontVariant="medium"
            to="/surveys"
            className={styles[`${baseClass}__link`]}
            activeClassName={styles[`${baseClass}__link_active`]}
          >
            {t("surveys")}
          </Typography>

          {type === "mobile" &&
              <Typography
              component={CustomLink}
              fontVariant="medium"
              to="/notifications"
              className={classNames(styles[`${baseClass}__link`], styles[`${baseClass}__notifications`])}
              activeClassName={styles[`${baseClass}__link_active`]}
            >
               <div> {t("notifications")}
               {!!notificationsCount && <div className={styles[`${baseClass}__unread`]}>{notificationsCount}</div>}
            
               </div>
              </Typography> }
         
        </div>
  )
}
export function UserNavigation() {
  const person = useSelector(personSelector);
  const [menu, setMenu] = useState(false);
  const ref = useRef(null);

  function handleMenu(e) {
    setMenu(true);
  }
  if (!person) {
    return null;
  }

  return (
    <div className={styles.user__wrapper} ref={ref}>
      <Avatar
        name={person.name}
        src={person.avatar?.small}
        textClassName={styles.avatar__text}
        imgClassName={styles.avatar}
        onClick={handleMenu}
      />
      <UserMenu
        person={person}
        isOpenMenu={menu}
        setIsopenMenu={setMenu}
        targetWrapper={ref}
      />
    </div>
  );
}

function UserMenu({ person, targetWrapper, setIsopenMenu, isOpenMenu }) {
  const [closingAnimate, setClosingAnimate] = useState(false);
  const [showBots, setShowBots] = useState(false);
  const [showThemes, setShowThemes] = useState(false);
  const [showLangs, setShowLangs] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const menu = useSelector(menuSelector);

  const { t } = useTranslation();
  const negate = useSelector(negateSelector);

  const close = useCallback(() => {
    setClosingAnimate(true);
    setTimeout(() => {
      setClosingAnimate(false);
      setIsopenMenu(false);
    }, 600);
  }, [setIsopenMenu, setClosingAnimate]);

  useClickOutside(targetWrapper, close);

  useEffect(() => {
    close();
  }, [pathname, close]);

  if (!isOpenMenu) {
    return null;
  }

  return (
    <div
      className={classNames(styles.user__popower, {
        [styles.user__popower_close]: closingAnimate,
      })}
    >
      <div className={styles.user__popower_cancel} onClick={close}>
        <CloseIcon />
      </div>
      <div className={styles.user__popower_header}>
        <Avatar
          name={person.name}
          src={person?.avatar?.big}
          textClassName={classNames(
            styles.avatar__text,
            styles.avatar__text_big
          )}
          imgClassName={classNames(styles.avatar, styles.avatar_big)}
          Typography
          fontVariant="medium"
        />
        <Typography fontVariant="medium" className={styles.user__name}>
          {person?.name}
        </Typography>
        <Typography fontVariant="medium" className={styles.user__email}>
          {person?.email}
        </Typography>
      </div>

      <ul className={styles.link__container}>
        {!!menu?.profile && (
          <li>
            <Typography
              component={Link}
              fontVariant=""
              className={styles.link}
              to="/profile"
            >
              {t("profile")}
            </Typography>
          </li>
        )}
        {!!menu?.bot_links && (
          <li
            className={styles.bots__wrapper}
            onClick={() => setShowBots((prev) => !prev)}
          >
            <div className={styles.bots__container}>
              <div
                className={classNames(styles.bots__container_connect, {
                  [styles.bots__opened]: showBots,
                })}
              >
                <Typography
                  component="p"
                  fontVariant=""
                  className={styles.link}
                >
                  {t("connect_messenger")}
                </Typography>
                <ArrowRightIcon />
              </div>
              {window.innerWidth > 768 && <Bots bots={menu?.bot_links} />}
              {window.innerWidth <= 768 && showBots && (
                <Bots bots={menu?.bot_links} />
              )}
            </div>
          </li>
        )}
        {!!menu?.support && (
          <li>
            <Typography
              component={Link}
              fontVariant=""
              className={styles.link}
              to="/support"
            >
              {t("faq")}
            </Typography>
          </li>
        )}

        <li className={styles.bots__wrapper} onClick={() => setShowThemes((prev) => !prev)}>
          <div className={styles.bots__container}>
            <div
              className={classNames(styles.bots__container_connect, {
                [styles.bots__opened]: showThemes,
              })}
            >
              <Typography component="p" fontVariant="" className={styles.link}>
                {t("appearance")}
              </Typography>
              <ArrowRightIcon />
            </div>
            {window.innerWidth > 768 && <Themes/>}
						{window.innerWidth <= 768 && showThemes && <Themes  />}
          </div>
        </li>
        {!!menu?.logout && (
          <Typography
            component="li"
            fontVariant=""
            onClick={() => {
              dispatch(logout());
            }}
          >
            {t("log_out")}
          </Typography>
        )}
      </ul>
      <div className={styles.user__popower_negate}>
        {/*<NegateChangeTheme />*/}
      </div>
    </div>
  );
}

export const MenuMobile = () => {
  const location = useLocation();
  const path = location?.pathname;
  const { t } = useTranslation();
  return (
    <>
      {window.innerWidth <= 1180 && (
        <div className={styles.headerMobile}>
          <Link
            to="/"
            className={classNames(styles.headerMobile__item, {
              [styles.headerMobile__active]: path === "/",
            })}
          >
            <DashboardIcon />
            <Typography fontVariant="">{t("dashboard")}</Typography>
          </Link>
          <Link
            to="/products"
            className={classNames(styles.headerMobile__item, {
              [styles.headerMobile__active]: path === "/products",
            })}
          >
            <BookIcon />
            <Typography fontVariant="">{t("my_courses")}</Typography>
          </Link>

          <Link
            to="/catalog"
            className={classNames(styles.headerMobile__item, {
              [styles.headerMobile__active]: path === "/catalog",
            })}
          >
            <DocumentTextIcon />
            <Typography fontVariant="">{t("catalog")}</Typography>
          </Link>
          <Link
            to="/knowledge-base"
            className={classNames(styles.headerMobile__item, {
              [styles.headerMobile__active]: path === "/knowledge-base",
            })}
          >
            <KnowbaseIcon />
            <Typography fontVariant="">{t("knowledge_base")}</Typography>
          </Link>
        </div>
      )}
    </>
  );
};

const Bots = ({ bots }) => {
  const { t } = useTranslation();

  if (!bots || !Object.keys(bots)?.length) {
    return null;
  }

  return (
    <div className={styles.bots}>
      <div className={styles.bots__list}>
        {Object.entries(bots).map(([key, value]) => (
          <Bot name={key} link={value} />
        ))}
      </div>
    </div>
  );
};

const Bot = ({ name, link }) => {
  const { t } = useTranslation();
  return (
    <Link
      to={link}
      target="_blank"
      className={classNames(styles.bot, styles?.[name])}
    >
      {name === "telegram" && <TelegramIcon />}
      {name === "viber" && <ViberIcon />}
      {name === "whatsapp" && <WhatsappIcon />}
      <Typography>{t(name + "_bot")}</Typography>
    </Link>
  );
};

const Themes = () => {
  const { t } = useTranslation();

  const negate = useSelector(negateSelector);
  const dispatch = useDispatch();

  const negateSetDark = () => {
    dispatch(setNegate(true));
    localStorage.setItem("negate", true);
  };

  const negateSetLight = () => {
    dispatch(setNegate(false));
    localStorage.setItem("negate", false);
  };
  return (
    <div className={styles.themes}>
      <div className={styles.themes__list}>
        <div className={styles.themes__item}  onClick={negateSetDark}>
		{negate && <TickIcon />}
          <Typography >{t("dark_mode")}</Typography>
        </div>
        <div className={styles.themes__item} onClick={negateSetLight}>
		{!negate && <TickIcon />}
          <Typography  >{t("light_mode")}</Typography>
        </div>
      </div>
    </div>
  );
};
