import { useEffect } from "react"
import classNames from "classnames"
import parse from "html-react-parser"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import {
	sendAnswer,
	sendFinish,
	surveySelector,
	surveysSelector,
	errorSelector,
} from "../../store/reducers/surveys"
import Typography from "../UIKit/base/Typography"
import InputControl from "../forms/input-control"
import styles from "./Survey.module.sass"
import Button from "../forms/button/Button"
import { Field, Form, Formik, useField } from "formik"
import Radio from "../forms/radio/Radio"
import { useTranslation } from "react-i18next"
import Checkbox from "../forms/checkbox/Checkbox"
import { useMemo } from "react"
import ConfirmModal from "../UIKit/modal/confirm-modal/ConfirmModal"
import {
	QuizIcon,
	TimerIcon,
	ArrowRightIcon,
	ArrowLeftIcon,
	QuestionIcon,
} from "../icons"
import { localeFormat } from "../localeFormat"
import { types } from "./utils"
import MobileHeader from "../Mobile/MobileHeader"
import {
	resetBreadcrumbs,
	setBreadcrumbs,
} from "../../store/reducers/breadcrumbs"
import Page404 from "../404"
import { isPast } from "date-fns"
import { globalLoadSelector } from "../../store/reducers/loader"

export default function Survey() {
	const { t } = useTranslation()
	const { surveyId, questionId } = useParams()
	const dispatch = useDispatch()
	const { question, previous_question_id, next_question_id, questionnaire } =
		useSelector(surveySelector)
	const surveys = useSelector(surveysSelector)
	const navigate = useNavigate()
	const isMobile = window.innerWidth <= 768
	const loading = useSelector(globalLoadSelector).loading

	const error = useSelector(errorSelector)

	const initialValues = useMemo(() => {
		if (!question?.answers?.length) {
			return { answer: "", ids: [] }
		}
		const { answers } = question
		const ids = answers
			.filter((e) => e.is_option)
			.map((e) => e.answer.toString())
		const answer = answers.find((e) => !e.is_option)
		return { ids, answer: answer?.answer || "" }
	}, [question])

	const surveyInfo = useMemo(() => {
		// eslint-disable-next-line eqeqeq
		return surveys?.find((s) => s?.id == surveyId) || null
	}, [surveys, surveyId])

	const crumbs = useMemo(() => {
		const arr = [
			// { path: `/`, title: t("dashboard") },
			{
				path: `/surveys`,
				title: t("scheduled_surveys"),
			},
			{
				path: ``,
				title: surveyInfo?.title,
			},
		]

		return arr
	}, [t, surveyInfo?.title])

	useEffect(() => {
		if (question && !loading) {
			dispatch(setBreadcrumbs(crumbs))
		}
		return () => {
			dispatch(resetBreadcrumbs())
		}
	}, [crumbs, dispatch, question, loading])

	function handleFinish({ close }, values) {
		dispatch(
			sendAnswer({
				surveyId,
				questionId,
				values,
				cb: dispatch(
					sendFinish({
						surveyId,
						cb: () => {
							close()
							navigate(`/surveys`)
						},
					})
				),
			})
		)
	}

	function handleNext(values) {
		if (values?.answer !== initialValues?.answer || question?.choices?.length) {
			let cb = () => {
				navigate(`/surveys/${surveyId}/questions/${next_question_id}`)
			}
			return dispatch(
				sendAnswer({
					surveyId,
					questionId,
					values,
					cb,
				})
			)
		}
		return navigate(`/surveys/${surveyId}/questions/${next_question_id}`)
	}
	function handlePrev() {
		navigate(`/surveys/${surveyId}/questions/${previous_question_id}`)
	}

	if (error === 404) {
		return <Page404 />
	}

	if (!question || !surveys?.length) {
		return null
	}

	return (
		<>
			<MobileHeader
				title={surveyInfo?.name}
				breadcrumbsTitle={t("scheduled_surveys")}
			/>
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<Typography fontVariant="bold" className={styles.title}>
						{surveyInfo?.title || ""}
					</Typography>
					<div className={styles.header__sub}>
						<Typography>{t("survey")}</Typography>
						<Typography
							className={classNames(styles.date, {
								[styles.date_less]:
									surveyInfo?.finish_date_at &&
									isPast(new Date(surveyInfo?.finish_date_at)),
							})}
						>
							<TimerIcon />
							{(!!surveyInfo?.finish_date_at &&
								localeFormat(surveyInfo?.finish_date_at)) ||
								""}
						</Typography>
					</div>
				</div>
				<div className={styles.container}>
					<div className={styles.card}>
						<Progress />
						<div className={styles.question__wrapper}>
							<QuestionType type={question?.type} />
							<Typography className={styles.question}>
								{question?.question}
							</Typography>
							{!!question?.description && 
							<Typography
								component="p"
								className={styles.question__description}
							>
								{question?.description}
							</Typography>
							}
						</div>
						<Formik
							initialValues={initialValues}
							onSubmit={handleNext}
							enableReinitialize
							key={questionId}
							validationSchema={yup.object().shape(
								{
									answer: yup.string().when("ids", {
										is: (ids) =>
											question?.rules?.min && question?.rules?.max
												? !(
														ids?.length >= question?.rules?.min &&
														ids?.length <= question?.rules?.max
												  )
												: !ids?.length,
										then: (schema) => schema.required(),
									}),
									ids: yup.array().when("answer", {
										is: (answer) => !answer?.length,
										then: (schema) => schema.required(),
									}),
								},
								["answer", "ids"]
							)}
						>
							{({ values }) => {
								const disabled =
									question?.rules?.min && question?.rules?.max
										? !(
												values?.ids?.length >= question?.rules?.min &&
												values?.ids?.length <= question?.rules?.max
										  )
										: !Object.keys(values)?.some((v) => values[v]?.length)

								return (
									<Form>
										<div className={styles.answers__wrapper}>
											<RenderFields
												type={question?.type}
												choices={question?.choices}
											/>
										</div>
										<div className={styles.button__group}>
											{previous_question_id && (
												<>
													<Button
														onClick={handlePrev}
														className={styles.button__prev}
														variant="secondary"
													>
														{t("previous")}
													</Button>
												</>
											)}

											{next_question_id ? (
												<Button disabled={disabled} type="submit">
													next_question
												</Button>
											) : (
												<ConfirmModal
													confirmType="confirm"
													submitLabel={t("finish")}
													label={t("finish_survey")}
													description={t("finish_survey_description")}
													onSubmit={(e) => {
														handleFinish(e, values)
													}}
													disabled={disabled}
												>
													<Button disabled={disabled} type="button">
														finish_survey
													</Button>
												</ConfirmModal>
											)}
										</div>
									</Form>
								)
							}}
						</Formik>
					</div>
					{questionnaire?.description && (
						<div className={`${styles.card} ${styles.desc}`}>
							<div className={styles.card__header}>
								<QuestionIcon />
								<Typography component="span">{t("about_survey")}</Typography>
							</div>
							<Typography className={styles.about}>
								{questionnaire?.description}
							</Typography>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

function Progress() {
	const { questions } = useSelector(surveySelector)
	const { surveyId, questionId } = useParams()

	if (!questions?.length) {
		return null
	}

	return (
		<div className={styles.progress__wrapper}>
			{questions?.map((s, i) => {
				return (
					<Typography
						key={`${s?.id}-question-${i}`}
						to={`/surveys/${surveyId}/questions/${s?.id}`}
						component={Link}
						className={classNames(styles.progress, {
							[styles.progress_active]: +questionId === s?.id || s?.is_answer,
							[styles.progress_selected]: +questionId === s?.id,
						})}
						onClick={(e) => {
							if (!s?.is_answer) {
								e.preventDefault()
							}
						}}
					/>
				)
			})}
		</div>
	)
}

function Confirmation({ name = "answer" }) {
	const { t } = useTranslation()
	return (
		<div className={styles.choise__wrapper}>
			<Radio name={name} value={1} className={styles.choise}>
				<Typography component="p" className={styles.text}>
					{t("yes")}
				</Typography>
			</Radio>
			<Radio name={name} value={0} className={styles.choise}>
				<Typography className={styles.text}>{t("no")}</Typography>
			</Radio>
		</div>
	)
}

function RadioGroup({ name = "ids", choices }) {
	return (
		<div className={styles.choise__wrapper}>
			{!!choices?.length &&
				choices?.map((c, i) => (
					<Radio
						key={`${c?.id}-radio-group-${i}`}
						name={name}
						value={c?.id}
						className={styles.choise}
						valueIsArray
					>
						<Typography component="p" className={styles.text}>
							{parse(c?.answer)}
						</Typography>
					</Radio>
				))}
		</div>
	)
}
function CheckboxGroup({ name = "ids", choices }) {
	return (
		<div className={styles.choise__wrapper}>
			{!!choices?.length &&
				choices?.map((c, i) => (
					<CheckBoxControl
						key={`${c?.id}-checkbox-group-${i}`}
						name={name}
						value={c?.id}
					>
						<Typography component="p" className={styles.text}>
							{c?.answer}
						</Typography>
					</CheckBoxControl>
				))}
		</div>
	)
}

function CheckBoxControl({ name, value, children }) {
	const [{ value: formValue }, _, { setValue }] = useField(name)
	function handleChange() {
		if (formValue.includes(value.toString())) {
			const arr = formValue.filter((e) => e !== value.toString())
			return setValue(arr)
		}
		return setValue([...formValue, value.toString()])
	}
	return (
		<div className={styles.choise}>
			<Checkbox
				name={name}
				value={value}
				className={styles.checkbox}
				onChange={handleChange}
				checked={formValue.includes(value.toString())}
			/>
			{children}
		</div>
	)
}

function RenderFields({ type, choices }) {
	const { t } = useTranslation()
	if (type === "confirmation") {
		return <Confirmation name="answer" />
	}
	if (type === "radio") {
		return <RadioGroup name="ids" choices={choices} />
	}
	if (type === "checkbox") {
		return <CheckboxGroup name="ids" choices={choices} />
	}

	return (
		<Field
			component={InputControl}
			name="answer"
			variant="textarea"
			placeholder={t("enter_your_answer")}
		/>
	)
}

function QuestionType({ type }) {
	const { t } = useTranslation()
	if (!types[type]) {
		return null
	}
	const Icon = types[type]?.icon
	return (
		<div className={styles.question__type}>
			<Icon />
			<Typography component="span">{t(types[type]?.name)}</Typography>
		</div>
	)
}
