import { useTranslation } from "react-i18next";
import { NoItemsImage } from "./NoItemsImage";
import style from "./products.module.scss";
import Typography from "../UIKit/base/Typography";

const NoItems = () => {
	const { t } = useTranslation();

	return (
		<Typography component="div" className={style.noItems}>
			<NoItemsImage />
			<h3>{t("no_courses")}</h3>
			<p>{t("you_have_no_courses")}</p>
		</Typography>
	);
};

export default NoItems;
