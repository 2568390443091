import styles from "./attachments.module.scss"
import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { AttachmentType } from "../../propTypes"
import {
	AttachmentPngIcon,
	AttachmentJpegIcon,
	AttachmentJpgIcon,
	AttachmentXlsxIcon,
	AttachmentXlsIcon,
	AttachmentZipIcon,
	AttachmentDocxIcon,
	AttachmentDocIcon,
	AttachmentPdfIcon,
	DocumentIcon,
} from "../icons"
import classNames from "classnames"
import Typography from "../UIKit/base/Typography"

function getSize(e) {
	const num = e / (1024 * 1024)
	if (num % 1 === 0) {
		return `${num.toFixed(0)} MB`
	}
	const res = num.toFixed(2)
	if (Number(res) % 1 === 0) {
		return `${(e / 1024).toFixed(2)} KB`
	}
	return `${res} MB`
}
export default function Attachments({
	attachments,
	className,
	titleClassName,
	course = false,
}) {
	if (!attachments?.length) {
		return null
	}

	return attachments?.map((e) => {
		const type = e.filename.split(".").pop()
		return (
			<div
				key={`${e.id}-${e.filename}`}
				className={classNames(styles.files_wrap, className)}
			>
				<Link
					to={e.url}
					filename={e.original_filename ? e.original_filename : e.filename}
					className={styles.link}
					download
				>
					<div className={styles.files_text_and_icon}>
						<Icon type={type} isCourse={course} />
						<div className={styles.title__wrapper}>
							<Typography className={classNames(styles.title, titleClassName)}>
								{e.original_filename ? e.original_filename : e.filename}
							</Typography>
							{course && e?.size ? (
								<Typography className={styles.size}>
									{getSize(e.size)}
								</Typography>
							) : null}
						</div>
					</div>
				</Link>
			</div>
		)
	})
}

export function AttachmentIcon({ type }) {
	switch (type) {
		case "pdf":
			return <AttachmentPdfIcon />
		case "zip":
			return <AttachmentZipIcon />
		case "doc":
			return <AttachmentDocIcon />
		case "docx":
			return <AttachmentDocxIcon />
		case "jpg":
			return <AttachmentJpgIcon />
		case "jpeg":
			return <AttachmentJpegIcon />
		case "png":
			return <AttachmentPngIcon />
		case "xls":
			return <AttachmentXlsIcon />
		case "xlsx":
			return <AttachmentXlsxIcon />
		case "ppt":
			return <DocumentIcon />
		case "pptx":
			return <DocumentIcon />
		default:
			return <DocumentIcon />
	}
}

Attachments.propTypes = {
	attachments: PropTypes.shape(AttachmentType),
}

const Icon = ({ type, isCourse }) => {
	if (isCourse) {
		return (
			<div className={styles.icon}>
				<AttachmentIcon type={type} />
			</div>
		)
	}
	return <AttachmentIcon type={type} />
}
