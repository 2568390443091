import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	lastNotificationsSelector,
	sendNotificationsViewed
} from "../../store/reducers/notifications";
import { NotificationIcon } from "../icons";
import style from "./Notifications.module.sass"; ///
import NotificationsPopup from "./NotificationsPopup"; // index

export const NotificationsButton = () => {
	const [showPopUp, setShowPopup] = useState(false);
	const [showUnread, setShowUnread] = useState(false);

	const dispatch = useDispatch()

	const notificationsData = useSelector(lastNotificationsSelector);
	const notifications = notificationsData?.notifications; //

	const unreadNotificationsCount = useMemo(() => {
		return notifications?.filter(
			(item) => item.viewed === 0
		)?.length;
	}, [notifications]) 

	useEffect(() => {
		!!unreadNotificationsCount && setShowUnread(true);
	}, [unreadNotificationsCount]);

	const handleClick = () => {
		setShowPopup((prevState) => !prevState);
	};

	const closePopup = () => {
		setShowPopup(false);
		setViewed()
	};

	const setViewed = () => {
		const unreadNotifications = notifications?.filter((item) => item.viewed === 0);

		if (unreadNotifications?.length) {
			const ids = unreadNotifications.map((item) => item.id);
			dispatch(sendNotificationsViewed({ ids: ids }));
			setShowUnread(false)
		}
 
	}

	return (
		<div className={style.notificationsButton}>
			<button onClick={handleClick}>
				<NotificationIcon />
			</button>
			{showUnread && <div className={style.notificationsButton__unread}>{unreadNotificationsCount}</div>}
			{showPopUp && <NotificationsPopup closePopup={closePopup} />}
		</div>
	);
};

 
		 