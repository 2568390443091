import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	notificationsSelector,
	getNotifications,
	sendNotificationsViewed,
} from "../../store/reducers/notifications";
import NotificationsList from "./NotificationsList";
import { createTabs } from "../UIKit/tabs/tabs";
import styles from "./Notifications.module.sass";
import { useTranslation } from "react-i18next";
import tabStyles from "../Products/products.module.sass";
import { useSearchParams } from "react-router-dom";
import { useRef } from "react";
import useScrollTop from "../hooks/useScrollTop";
import NoItems from "./NoItems";
import MobileTitle from "../Mobile/MobileTitle";
import Typography from "../UIKit/base/Typography"
import image from "../Preloader/Loading_Step.gif"
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader";
import PreloaderUser from "../Preloader/Preloaders/PreloaderUser";
import PreloaderNotifications from "../Preloader/Preloaders/PreloaderNotifications";

const Notifications = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	let [searchParams] = useSearchParams();
	const type = searchParams.get("type");
	const containerRef = useRef();
	const notificationsData = useSelector(notificationsSelector);
	const notifications = notificationsData?.notifications?.items;

	const [load, setLoad] = useState(false)

	const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		const unreadNotifications = notifications?.filter((item) => item.viewed === 0);

		if (unreadNotifications?.length) {
			const ids = unreadNotifications.map((item) => item.id);
			dispatch(sendNotificationsViewed({ ids: ids }));
		}
	}, [notifications])

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])

	useScrollTop(searchParams);

	useEffect(() => {
		dispatch(getNotifications({ type: type || "", page: 1 }));
	}, [type, dispatch]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [notificationsData]);

	const handleScroll = (event) => {
		if (
			notificationsData?.notifications?.has_more_pages &&
			window.innerHeight + window.scrollY > containerRef?.current?.scrollHeight
		) {
			setLoad(true)
			const pageNumber =
				notificationsData.notifications.next_page_url.split("page=")[1];
			dispatch(getNotifications({ type: type || "", page: pageNumber, cb: () => setLoad(false) }));
		}
	};

	function viewTabs() {
		return (
			<>
				<Typography
					component="h2"
					className={styles.notifications__title}
					fontVariant="bold"
				>
					{t("notifications")}
				</Typography>
				<div className={tabStyles.line}>
					<div className={tabStyles.tabLine}>
						{createTabs({
							text: t("all"),
							url: `/notifications`,
							style: {
								color: !type ? "active" : "secondaryDarkGray",
							},
						})}
						{createTabs({
							text: t("products"),
							url: `/notifications?type=products`,
							style: {
								color: type === "products" ? "active" : "secondaryDarkGray",
							},
						})}
						{createTabs({
							text: t("lessons"),
							url: `/notifications?type=lessons`,
							style: {
								color: type === "lessons" ? "active" : "secondaryDarkGray",
							},
						})}
						{createTabs({
							text: t("security"),
							url: `/notifications?type=security`,
							style: {
								color: type === "security" ? "active" : "secondaryDarkGray",
							},
						})}
						{createTabs({
							text: t("feedback"),
							url: `/notifications?type=reviews`,
							style: {
								color: type === "reviews" ? "active" : "secondaryDarkGray",
							},
						})}
						{createTabs({
							text: t("custom"),
							url: `/notifications?type=custom`,
							style: {
								color: type === "custom" ? "active" : "secondaryDarkGray",
							},
						})}
						{createTabs({
							text: t("kb_access"),
							url: `/notifications?type=kb_doc_access`,
							style: {
								color: type === "kb_doc_access" ? "active" : "secondaryDarkGray",
							},
						})}
					</div>
				</div>
			</>
		);
	}

	if(loading){
		return  <PreloaderNotifications />
	  }
	

	return (
		<div ref={containerRef} className={styles.notifications}>
			<MobileTitle title={t("all_notifications")} />
			{viewTabs()}
			{!notifications?.length && <NoItems />}
			{!!notifications?.length && <NotificationsList />}
			{load && <div className={styles.notifications__preloader}><img src={image} alt="preloader" /></div>}
		</div>
	);
};

export default Notifications;
