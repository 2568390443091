import { useSelector } from "react-redux"
import { authSelector } from "../../store/reducers/auth"
import { useLocation, Navigate } from "react-router-dom"
import React from "react"
import AiWidget from "../AiWidget/AiWidget"

export default function ProtectedRoute({ component: Component, ...props }) {
	const auth = useSelector(authSelector)
	const location = useLocation()

	if (!auth) {
		return <Navigate to="/login" state={{ from: location }} />
	}

	return (
		<>
			<Component {...props} />
			<AiWidget />
		</>
	)
}
