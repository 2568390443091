import classNames from "classnames"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { autologin } from "../../store/reducers/auth"
import styles from "./login.module.sass"

import LoginForm from "./LoginForm"
import Slider from "./Slider/Slider"
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader"
import PreloaderLogin from "../Preloader/Preloaders/PreloaderLogin"


const Login = () => {
	const [query] = useSearchParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const loading = useSelector(globalLoadSelector).loading
	const location = useLocation()

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])

	useEffect(() => {
		const token = query.get("token")
		if (token) {
			dispatch(
				autologin({
					token,
					cb: () => {
						navigate("/")
					},
				})
			)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	//if(loading){
		//return <PreloaderLogin />
//	}
	return (
		<> 
		{!!loading && <div className={styles.preloader}><PreloaderLogin /></div>}
		<main className={styles.auth}>
			{/*!!loading && <div className={styles.auth__loader}><PreloaderLogin /></div>*/}
			<div className={classNames(styles.col, styles.left)}>
				<Slider />
			</div>
			<div className={classNames(styles.col, styles.right)}>
				<LoginForm location={location} />
			</div>
		</main>
		
		</>
	)
}

export default Login
