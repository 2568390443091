import classNames from "classnames"
import { useSelector } from "react-redux"
import { fontSelector } from "../../../store/reducers/domainTheme"
import { capitalize } from "../../utils"
import "./index.sass"
import { forwardRef } from "react"

const Typography = forwardRef(
	(
		{ component: Component = "div", fontVariant = "", className, ...props },
		ref
	) => {
		const themeFont = useSelector(fontSelector)
		const defaultFont = "Gilroy"
		const font = themeFont || defaultFont
		const name =
			font + (fontVariant === "regular" ? "" : capitalize(fontVariant))
		return (
			<Component ref={ref} className={classNames(name, className)} {...props} />
		)
	}
)



export default Typography
